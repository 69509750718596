
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { AXIOS_POST, clearToken, SET_TOKEN, SET_USER } from "../service/api.service";

const authContext = React.createContext();

export function useAuth() {
    const [authed, setAuthed] = React.useState(false);
    const navigate = useNavigate()
    return {
        authed,
        async login(userName, password) {
            // return new Promise((res) => {
            //     setAuthed(true);
            //     res();
            // });
            const loginAPICall = await AXIOS_POST('/api/auth/email/login', {
                "email": userName,
                "password": password
            })
            if (loginAPICall.status) {
                console.log("SET auth", loginAPICall)
                const token = loginAPICall.data.token
                setAuthed(true)
                SET_TOKEN(token)
                SET_USER(loginAPICall.data.user)
                // SET_USER_ACCESS()
                return loginAPICall
            }
            else {
                return loginAPICall
            }

        },
        logout() {
            return new Promise((res) => {
                setAuthed(false);
                clearToken()
                navigate('/')
                res();
            });
        },
    };
}

export function AuthProvider({ children }) {
    const auth = useAuth();


    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
    return React.useContext(authContext);
}