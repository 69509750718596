/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Background from "../../assets/forgotbg.jpg";
import avatar from "../../assets/Ellipse 17.png";
import Grid from "@mui/material/Grid";
import Avatar from '@mui/material/Avatar';
import car from '../../assets/car.png'
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import HistoryIcon from '@mui/icons-material/History';

import MenuIcon from '@mui/icons-material/Menu';
import { AXIOS_GET, AXIOS_POST, GET_USER } from "../../service/api.service";
import { useSnackbar } from "notistack";
import ConfirmationPopup from "../shared/ConfirmationPopup";
import { CircularProgress } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
const useStyles = makeStyles({
  root: {
    height: "100vh",
    backgroundImage: `url(${Background})`,
    width: "100%",
    backgroundSize: "cover",

    // backgroundColor:'#133366'
  },
  declinebtn: {
    borderRadius: "40px",
    backgroundColor: "#E21E34",
    color: "white",
    border: "none",
    fontSize: "18px",
    width: "100%",
    height: "50px",
    fontWeight: 600,
  },
  tripinfo: {
    borderRadius: "8px",
    backgroundColor: "#E21E34",
    color: "white",
    border: "none",
    fontSize: "12px",
    width: "92%",
    height: "30px",
    fontWeight: 600,
  },
  acceptbtn: {
    borderRadius: "40px",
    backgroundColor: "white",
    color: "#133366",
    border: "none",
    fontSize: "18px",
    width: "100%",
    height: "50px",
    fontWeight: 600,
  },
  trip: {
    paddingTop: "50px",
    // position: "fixed",
    // bottom: 0,
    width: "100%",
  },
  btndiv: {
    margin: "20px",
  },
  boxdiv: {
    background: "linear-gradient(to right,  #E21E34 5%, #153265 95%)",
    border: "1px solid #FFFFFF",
    borderRadius: "20px",
    height: "110px"
  },
  avtimg: {
    width: "60px",
    height: "60px",
    border: "2px solid white"
  },
  details: {
    fontSize: "12px",
    textAlign: "left",
    marginBottom: "0px",
    marginTop: "0px",
    color: "white"
  },
  detailspickup: {
    fontSize: "8px",
    textAlign: "left",
    marginBottom: "0px",
    marginTop: "0px",
    color: "lightgray"
  },
  detailspickuptime: {
    fontSize: "10px",
    textAlign: "left",
    marginBottom: "0px",
    marginTop: "0px",
    color: "white"
  },
  horizontalCenter: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10
  },
  noDataFound: {
    color: 'white',
    fontSize: '1.2rem'
  }
});

export default function Home() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [driver, setDriver] = useState(null)
  const [vehicle, setvehicle] = useState(null)
  const [route, setRoute] = useState(null)
  const [loader, setLoader] = useState(false)
  const [buttonProps, setButtonProps] = useState(null)
  const [currentStatus, setCurrentStatus] = useState(null)
  const [trip, setTrip] = useState(null)
  const [user, setUser] = useState(null)
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const { logout } = useAuth()

  useEffect(() => {
    console.log("UserEffect -- ")
    makeCallForTrip()
    getUserMeta()
  }, [])

  // get user meta 
  const getUserMeta = () => {
    console.log("get user meta 2 ")
    const userD = GET_USER()
    setUser(userD)
  }

  // API CALL for getting th whole route detail
  const makeCallForTrip = async () => {
    console.log("get user meta 1")

    setLoader(true)
    const upcomingTrip = await AXIOS_GET('/api/route/available-trip', {}, {})
    setLoader(false)

    if (upcomingTrip.status) {
      setDriver(upcomingTrip.data?.route?.driver)
      setvehicle(upcomingTrip.data?.route?.vehicle)
      setRoute(upcomingTrip.data?.route)
      setCurrentStatus(upcomingTrip?.data?.currentStatus)
      setTrip(upcomingTrip?.data)
    }
    else {
      enqueueSnackbar('No trip found', { variant: 'error' })
    }
    console.log(upcomingTrip)
  }

  // API CALL for chaging status of the rid
  const changeStatus = async (status) => {
    setLoader(true)
    const url = `/api/route/update-trip-status/${trip.id}`
    const statusAPI = await AXIOS_POST(url, { currentStatus: status })
    setLoader(false)

    console.log("statusAPI", statusAPI)
    await makeCallForTrip()
  }

  // Show button props based on some logic
  const showButtonText = () => {
    let props = {}
    console.log("showButtonText", currentStatus)
    switch (currentStatus) {
      case "Not Accepted":
        props = { buttonLabel: "I Accept the ride", buttonKey: 'Accept', color: 'white' }
        break;
      case "Accept":
        props = { buttonLabel: "I have boarded", buttonKey: 'Boarded', color: '#FF8D33', }
        break;
      case "Boarded":
        props = { buttonLabel: "Drop complete", buttonKey: 'Dropped', color: '#3FFF33' }
        break;
      default:
        break;
    }
    console.log(props)
    return (<button onClick={() => onClickButton_1(props.buttonKey)} className={classes.acceptbtn} style={{ backgroundColor: props.color }}>{props.buttonLabel}</button>)
  }

  // decline button props
  const showButton_2 = () => {
    let props = {}
    switch (currentStatus) {
      case "Not Accepted":
        props = { buttonLabel: "I Decline the ride", buttonKey: 'Declined' }
        return (<button onClick={() => onClickButton_1(props.buttonKey)} className={classes.declinebtn}>{props.buttonLabel}</button>)
        break;
      default:
        break;
    }

  }

  // handling confirmation modal 
  const onClickButton_1 = (status) => {
    console.log("status", status)
    // changeStatus(status)
    setSelectedStatus(status)
    setConfirmationOpen(true)
  }

  // on click of yes in modal, make this call
  const onStatusAPICall = () => {
    const status = selectedStatus
    changeStatus(status)
    setSelectedStatus(null)
    setConfirmationOpen(false)
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // logic for displaying time of trip start and end
  const timeFormat = (date) => {
    console.log("date", date)
    if (date) {
      return new Date(date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    }
    else {
      return "-"
    }
  }

  const add530 = (dated) => {
    const date = new Date(dated)
    if(date){
      date.setHours(date.getHours() - 5);
      date.setMinutes(date.getMinutes() - 30);
      // debugger
      return timeFormat(date)
    }
    else{
      return "-"
    }
  }



  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{ backgroundColor: "#153265", color: "white", height: "100%" }}
    >
      <Grid direction="column" container alignItems="center" justifyContent="center" rowSpacing={1} style={{ marginTop: 10 }} columnSpacing={{ xs: 1 }}>
        <Grid item xs={6}>
          <Avatar
            className={classes.avtimg}
            alt="Remy Sharp"
            src={user && user.photo || avatar}
            style={{ width: "60px", height: "60px" }}
          />
        </Grid>
        <Grid xs={6} item>
          <p>{user && `Hi ${(user.firstName || "") + ' ' + (user.lastName || "")}`}</p>
        </Grid>
      </Grid>
      <List>
        <Link to="/home" style={{ textDecoration: "none", color: "white" }}>
          <ListItem disablePadding>

            <ListItemButton>
              <ListItemIcon>
                <HomeIcon style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Divider style={{ backgroundColor: "white" }} />
        <Link to="/triphistory" style={{ textDecoration: "none", color: "white" }}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <HistoryIcon style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText primary="Trip History" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Divider style={{ backgroundColor: "white" }} />

        <Link onClick={() => logout()} style={{ textDecoration: "none", color: "white" }}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <HistoryIcon style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
    </Box>
  );
  return (
    <div className={classes.root}>
      <div>
        {['left'].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)}><MenuIcon style={{ color: "white" }} /></Button>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
      {/* {JSON.stringify(route)} */}
      {
        route && <Grid className={classes.trip}>
          <Grid style={{ margin: "0px 20px" }} >
            <div className={classes.horizontalCenter}>
              {loader && <CircularProgress size="1.5rem" style={{ color: '#fff' }} />}

            </div>
            <div className={classes.boxdiv}>
              <Grid container justifyContent="space-between" alignItems="center" style={{ height: "100%" }}>
                <Grid xs={3} container alignItems="center" justifyContent="center">
                  <Avatar className={classes.avtimg} alt="Remy Sharp" src={driver && driver.photo || avatar} style={{ width: "60px", height: "60px" }} />
                </Grid>
                {/* <Grid xs={4} container alignItems="center" justifyContent="flex-start" flexDirection={"column"}> */}
                <div>
                  <p className={classes.details}><b>{driver && driver.name || ""} </b></p>
                  <p className={classes.detailspickuptime}>{vehicle && vehicle.vehiceMake || "-"} </p>
                  <p className={classes.detailspickuptime}>{vehicle && vehicle.vehiceNo || "-"}</p>
                  <p className={classes.detailspickuptime}>+91 {driver && driver.phoneNumber || "-"} </p>
                  <p className={classes.detailspickup}>--&nbsp;Pickup Time&nbsp;--</p>
                  <p className={classes.detailspickuptime}>{add530(trip.pickupTime)}</p>
                </div>
                <Grid xs={5} container justifyContent="flex-end">
                  <Link to="/tripdetails" style={{ width: "100%" }}>
                    <button className={classes.tripinfo}>Trip info</button>
                  </Link>
                  <img src={car} alt='car' style={{ height: '7vh' }}></img>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <ConfirmationPopup
            open={confirmationOpen}
            setOpen={() => setConfirmationOpen(!confirmationOpen)}
            title="Confirmation"
            message="Are you sure to proceed?"
            onClose={() => { setConfirmationOpen(false) }}
            onYes={() => { onStatusAPICall() }}
          />
          <div className={classes.btndiv}>
            {/* <button className={classes.acceptbtn}>Accept Trip</button> */}
            {showButtonText()}
          </div>
          <div className={classes.btndiv}>
            {/* <button className={classes.declinebtn}>Decline Trip</button> */}
            {showButton_2()}
          </div>
        </Grid>
      }
      {
        route == null && (
          <Grid className={classes.trip}>
            <Grid style={{ margin: "0px 20px" }} >
              <div className={classes.horizontalCenter}>
                <p className={classes.noDataFound}>No Trip Found</p>
              </div>
            </Grid>
          </Grid>
        )
      }

    </div>
  );
}
