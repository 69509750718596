import "./App.css";
import Home from "./components/home/home";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/login/login";
import TrpiDetails from "./components/tripDetails/tripdetails";
import TripHistory from "./components/tripHistory/triphistory";
import { AuthProvider, useAuth } from "./hooks/useAuth";
import { SnackbarProvider, useSnackbar } from "notistack";
import React from "react";
// import {
//   makeStyles
// } from '@mui/core';

function RequireAuth({ children }) {
  const token = localStorage.getItem('accessToken')
  return token ? children : <Navigate to="/" replace />;
}

function CheckAuthAndNavigate({ children }) {
  const token = localStorage.getItem('accessToken')
  return token ? <Navigate to="/home" replace /> : children

}


function App() {
  return (
    // <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <SnackbarProvider
          dense
        >
          <Routes>

            <Route path="/" element={
              <CheckAuthAndNavigate>
                <Login />
              </CheckAuthAndNavigate>
            } />
            <Route path="/home" element={
              <RequireAuth><Home />
              </RequireAuth>} />
            <Route path="/tripdetails" element={
              <RequireAuth><TrpiDetails /></RequireAuth>} />
            <Route path="/triphistory" element={<RequireAuth>
              <TripHistory />
            </RequireAuth>} />
          </Routes>
        </SnackbarProvider>
      </AuthProvider>
    </BrowserRouter>
    // </React.StrictMode>
    // <div>
    //     <Login/>
    //     <Home/>
    //     <TrpiDetails/>
    //     <TripHistory/>
    // </div>
  );
}

export default App;
