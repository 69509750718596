/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import Background from '../../assets/loginbg.jpg'
import logo from '../../assets/ic_main_logo.png'
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { getToken } from "../../service/api.service";

const useStyles = makeStyles({
  root: {
    height: "100vh",
    backgroundImage: `url(${Background})`,
    width: "100%",
    backgroundSize: "cover",
    // backgroundColor:'#133366'
  },
  logoimg: {
    paddingTop: "100px"
  },
  inputdiv: {
    padding: "20px"
  },
  headings: {
    color: "white"
  },
  inputbox: {
    backgroundColor: "#D9D9D9",
    borderRadius: "5px",
    height: "50px",
    opacity: "50%",
    width: "100%",
    fontSize: '15px',
    border: "none",
    marginBottom: '15px',
    fontWeight: 'bold'
  },
  forrgottxt: {
    textAlign: "right",
    color: "white",
    fontSize: "18px",
    marginBottom: '15px'
  },
  loginbtn: {
    borderRadius: "40px",
    backgroundColor: "white",
    color: "#133366",
    border: "none",
    fontSize: "18px",
    width: "100%",
    height: "50px",
    fontWeight: 600
  }
});

export default function Login() {
  const classes = useStyles();
  const { login } = useAuth()
  const [userName, setUserName] = useState(null)
  const [password, setPassword] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const [loader, setLoader] = useState(false)
  // const history = useHistory()
  const navigate = useNavigate();


  const handleLogin = async () => {
    console.log("Login App")
    if (userName && password) {
      setLoader(true)
      const log = await login(userName, password)
      setLoader(false)

      if (log.status) {
        enqueueSnackbar('Logged in!', { variant: 'success' })
        navigate('/home')
      } else {
        enqueueSnackbar(log.message, { variant: 'warning' })
      }
      console.log(log)
    }
    else {
      enqueueSnackbar('Invalid credentials entered', { variant: 'info' })
    }
  }

  return <div className={classes.root}>
    <Grid container justifyContent="center" className={classes.logoimg}>
      <img src={logo} alt='Kgisl' style={{ height: '6vh' }}></img>
    </Grid>
    <Grid className={classes.inputdiv}>
      <h1 className={classes.headings}>Login</h1>
      <input type={"text"} placeholder="Enter Your ID" onChange={(e) => setUserName(e.target.value)} className={classes.inputbox}></input>
      <input type={"password"} placeholder="Enter Your Password" onChange={(e) => setPassword(e.target.value)} className={classes.inputbox}></input>
      <p className={classes.forrgottxt}>Forgot Password</p>
      <Link >
        <button
          disabled={loader}
          onClick={handleLogin} className={classes.loginbtn}>
          {loader && <CircularProgress size="1rem" />} LOGIN
        </button>
      </Link>
    </Grid>
  </div>
}
