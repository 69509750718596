/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Background from "../../assets/forgotbg.jpg";
import Grid from "@mui/material/Grid";
import line from "../../assets/Group 49.png";
import location from "../../assets/Location on.png";
import { Button, Divider } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDown from '@mui/icons-material/ArrowDownwardOutlined';

import { Link } from "react-router-dom";
import { AXIOS_GET } from "../../service/api.service";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
  root: {
    height: "100vh",
    backgroundImage: `url(${Background})`,
    width: "100%",
    backgroundSize: "cover",

    // backgroundColor:'#133366'
  },
  declinebtn: {
    borderRadius: "40px",
    backgroundColor: "#E21E34",
    color: "white",
    border: "none",
    fontSize: "18px",
    width: "100%",
    height: "50px",
    fontWeight: 600,
  },
  tripinfo: {
    borderRadius: "8px",
    backgroundColor: "#E21E34",
    color: "white",
    border: "none",
    fontSize: "12px",
    width: "60%",
    height: "30px",
    fontWeight: 600,
  },
  acceptbtn: {
    borderRadius: "40px",
    backgroundColor: "white",
    color: "#133366",
    border: "none",
    fontSize: "18px",
    width: "100%",
    height: "50px",
    fontWeight: 600,
  },
  trip: {
    position: "fixed",
    bottom: 0,
    width: "100%",
  },
  btndiv: {
    margin: "20px",
  },
  boxdiv: {
    background: "linear-gradient(to right,  #E21E34 5%, #153265 95%)",
    border: "1px solid #FFFFFF",
    borderRadius: "20px",
    height: "110px",
  },
  avtimg: {
    width: "60px",
    height: "60px",
    border: "2px solid white",
  },

  infotext: {
    fontSize: "17px",
    textAlign: "left",
    marginBottom: "0px",
    color: "white",
    fontWeight: "bold",
  },
  details: {
    fontSize: "12px",
    textAlign: "left",
    marginBottom: "0px",
    marginTop: "0px",
    color: "white",
  },
  detailspickup: {
    fontSize: "12px",
    textAlign: "left",
    marginBottom: "0px",
    marginTop: "0px",
    color: "lightgray",
  },
  detailspickuptime: {
    fontSize: "14px",
    textAlign: "left",
    marginBottom: "0px",
    marginTop: "0px",
    color: "white",
    fontWeight: "bold",
  },
  vl: {
    borderLeft: "1px solid grey",
    height: "20px",
    marginLeft: "10px",
    marginRight: "10px",
  },
  backarow: {
    color: "white",
    paddingTop: "20px",
    paddingLeft: '15px'
  },
  centerLoad: {
    display: 'flex',
    justifyContent: 'center'
  }
});

export default function TripHistory() {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [searchKey, setSearchKey] = useState('')
  const [tripHistoryList, setTripHistoryList] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    console.log("TripHistory")
    getTripHistory()
  }, [])



  // logic for displaying time of trip start and end
  const dateFormat = (date) => {
    console.log("date", date)
    if (date) {
      return new Date(date).toDateString()
    }
    else {
      return "-"
    }
  }


  const timeFormat = (date) => {
    console.log("date", date)
    if (date) {
      return new Date(date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    }
    else {
      return "-"
    }
  }


  const getTripHistory = async (pageV = page, limitV = limit, searchKeyV = searchKey) => {
    const historyD = await AXIOS_GET('/api/route/available-trip/history', {
      page: pageV,
      limit: limitV,
      searchKey: searchKeyV
    })
    if (historyD.status) {
      console.log("historyD", historyD)
      setTripHistoryList([...tripHistoryList, ...historyD.data])
      setTotalCount(historyD.totalCount)
    }
    else {
      enqueueSnackbar('Unable to fetch trip history', { variant: 'error' })
    }
  }

  const loadMore = () => {
    console.log("load more")
    const newPage = page + 1
    getTripHistory(newPage)
    setPage(newPage)
  }

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Link to="/home">
        <ArrowBackIcon className={classes.backarow} />
      </Link>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: "10px 15px" }}
      >
        <Grid xs={12} container alignItems="center" justifyContent="flex-start">
          <p className={classes.infotext}>Trip History</p>
        </Grid>
      </Grid>

      {
        tripHistoryList.map(tripInfo => {
          return (
            <div>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{
                  backgroundColor: "rgb(167 89 89)",
                  padding: "6px",
                }}
              >
                <Grid>
                  <p className={classes.detailspickuptime}>Trip No:{tripInfo.id}</p>
                </Grid>
                <Grid>
                  <p className={classes.detailspickuptime}>{dateFormat(tripInfo?.route?.dateOfTrip)} </p>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                style={{ padding: "20px" }}
              >
                <Grid xs={1}>
                  <img src={line} alt="line" style={{ height: "7vh" }}></img>
                </Grid>
                <Grid xs={11}>
                  <Grid container xs={12}>
                    <Grid xs={8}>
                      <div style={{ display: "flex" }}>
                        <div>
                          <img src={location} alt="car"></img>
                        </div>
                        <div className={classes.vl}></div>
                        <div>
                          <p className={classes.details}>Source: {tripInfo?.route?.fromAddress} </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid xs={4} container justifyContent="flex-end">
                      <p className={classes.detailspickuptime}>{timeFormat(tripInfo?.route?.expStartTime)} </p>
                    </Grid>
                  </Grid>
                  <Divider style={{ backgroundColor: "white", margin: "6px" }} />
                  <Grid container xs={12}>
                    <Grid xs={8}>
                      <div style={{ display: "flex" }}>
                        <div>
                          <img src={location} alt="car"></img>
                        </div>
                        <div className={classes.vl}></div>
                        <div>
                          <p className={classes.details}>Destination: {tripInfo?.route?.toAddress} </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid xs={4} container justifyContent="flex-end">
                      <p className={classes.detailspickuptime}>{timeFormat(tripInfo?.route?.expEndTime)} </p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )
        })
      }
      {
        totalCount > tripHistoryList.length && <div className={classes.centerLoad}>
          <Button onClick={loadMore} style={{ color: 'white', fontSize: '0.6rem' }}><ArrowDown style={{ color: "white", fontSize: '0.8rem' }} /> Load More</Button>
        </div>
      }


    </div>
  );
}
