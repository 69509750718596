/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Background from "../../assets/forgotbg.jpg";
import plate from "../../assets/Group 45.png";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import car from "../../assets/car.png";
import avatar from "../../assets/Ellipse 17.png";
import call from "../../assets/Group 27.png";
import line from "../../assets/Group 49.png";
import location from "../../assets/Location on.png";
import { CircularProgress, Divider } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from "react-router-dom";
import { AXIOS_GET } from "../../service/api.service";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
  root: {
    height: "100vh",
    backgroundImage: `url(${Background})`,
    width: "100%",
    backgroundSize: "cover",

    // backgroundColor:'#133366'
  },
  declinebtn: {
    borderRadius: "40px",
    backgroundColor: "#E21E34",
    color: "white",
    border: "none",
    fontSize: "18px",
    width: "100%",
    height: "50px",
    fontWeight: 600,
  },
  tripinfo: {
    borderRadius: "8px",
    backgroundColor: "#E21E34",
    color: "white",
    border: "none",
    fontSize: "12px",
    width: "60%",
    height: "30px",
    fontWeight: 600,
  },
  acceptbtn: {
    borderRadius: "40px",
    backgroundColor: "white",
    color: "#133366",
    border: "none",
    fontSize: "18px",
    width: "100%",
    height: "50px",
    fontWeight: 600,
  },
  trip: {
    position: "fixed",
    top: 0,
    width: "100%",
  },
  btndiv: {
    margin: "20px",
  },
  boxdiv: {
    background: "linear-gradient(to right,  #E21E34 5%, #153265 95%)",
    border: "1px solid #FFFFFF",
    borderRadius: "20px",
    height: "110px",
  },
  avtimg: {
    width: "60px",
    height: "60px",
    border: "2px solid white",
  },

  infotext: {
    fontSize: "17px",
    textAlign: "left",
    marginBottom: "0px",
    marginTop: "0px",
    color: "white",
  },
  details: {
    fontSize: "12px",
    textAlign: "left",
    marginBottom: "0px",
    marginTop: "0px",
    color: "white",
  },
  detailspickup: {
    fontSize: "12px",
    textAlign: "left",
    marginBottom: "0px",
    marginTop: "0px",
    color: "lightgray",
  },
  detailspickuptime: {
    fontSize: "14px",
    textAlign: "left",
    marginBottom: "0px",
    marginTop: "0px",
    color: "white",
    fontWeight: "bold",
  },
  vl: {
    borderLeft: "1px solid grey",
    height: "20px",
    marginLeft: "10px",
    marginRight: "10px",
  },
  backarow: {
    color: "white",
    paddingTop: "20px",
    paddingLeft: '15px'
  }
});

export default function TrpiDetails() {
  const classes = useStyles();

  const [driver, setDriver] = useState(null)
  const [vehicle, setvehicle] = useState(null)
  const [route, setRoute] = useState(null)
  const [loader, setLoader] = useState(false)
  const [trip, setTrip] = useState(null)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    makeCallForTrip()
  }, [])


  // API CALL for getting th whole route detail
  const makeCallForTrip = async () => {
    setLoader(true)
    const upcomingTrip = await AXIOS_GET('/api/route/available-trip', {}, {})
    setLoader(false)

    if (upcomingTrip.status) {
      setDriver(upcomingTrip.data?.route?.driver)
      setvehicle(upcomingTrip.data?.route?.vehicle)
      setRoute(upcomingTrip.data?.route)
      setTrip(upcomingTrip?.data)
    }
    else {
      enqueueSnackbar('No trip found', { variant: 'error' })
    }
    console.log(upcomingTrip)
  }


  // logic for displaying time of trip start and end
  const timeFormat = (date) => {
    console.log("date", date)
    if (date) {
      return new Date(date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    }
    else {
      return "-"
    }
  }

  return (
    <div className={classes.root}>
      <Link to="/home">
        <ArrowBackIcon className={classes.backarow} />
      </Link>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: "45px 25px" }}
      >
        <div className={classes.horizontalCenter}>
          {loader && <CircularProgress size="1.5rem" style={{ color: '#fff' }} />}

        </div>
        <Grid
          xs={6}
          container
          alignItems="center"
          justifyContent="flex-start"
          style={{ lineHeight: "36px" }}
        >
          <p className={classes.infotext}>{vehicle && vehicle.vehiceMake || ""} </p>
          <p className={classes.infotext}>{vehicle && vehicle.vehiceNo || "-"}</p>
        </Grid>
        <Grid xs={6} container alignItems="center" justifyContent="center">
          <img src={car} alt="car" style={{ height: "12vh", zIndex: 1 }}></img>
          <img
            src={plate}
            alt="car"
            style={{ height: "6vh", marginTop: "-40px" }}
          ></img>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid xs={3} container alignItems="center" justifyContent="center">
          <Avatar
            className={classes.avtimg}
            alt="Remy Sharp"
            src={driver && driver.photo || avatar}
            style={{ width: "60px", height: "60px" }}
          />
        </Grid>
        <Grid xs={4} container alignItems="center" justifyContent="flex-start">
          <p className={classes.details}>
            <b>{driver && driver.name || ""} </b>
          </p>
          <p className={classes.detailspickuptime}>{vehicle && vehicle?.vehiceMake && (vehicle?.vehiceMake + ', ') || ""}{vehicle && vehicle?.vehiceModel || ""} </p>
        </Grid>
        <Grid xs={5} container alignItems="center" justifyContent="center">

          <img src={call} alt="car" style={{ height: "7vh" }}></img><br />

        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" style={{ paddingRight: "20px" }}>
        <p className={classes.detailspickuptime}> Route  {route && route.id || ""}</p>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ backgroundColor: "rgb(167 89 89)", padding: "6px", marginTop: '20px' }}
      >
        <Grid>
          <p className={classes.detailspickuptime}>Trip No:{trip && trip.id}</p>
        </Grid>
        <Grid>
          <p className={classes.detailspickuptime}>{route && route.dateOfTrip && new Date(route.dateOfTrip).toDateString()} </p>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        style={{ padding: "20px" }}
      >
        <Grid xs={1}>
          <img src={line} alt="line" style={{ height: "7vh" }}></img>
        </Grid>
        <Grid xs={11}>
          <Grid container xs={12}>
            <Grid xs={8}>
              <div style={{ display: "flex" }}>
                <div>
                  <img src={location} alt="car"></img>
                </div>
                <div className={classes.vl}></div>
                <div>
                  <p className={classes.details}>Source: {route && route.fromAddress || "-"} </p>
                </div>
              </div>
            </Grid>
            <Grid xs={4} container justifyContent="flex-end">
              <p className={classes.detailspickuptime}>{route && route.expStartTime && timeFormat(route.expStartTime)} </p>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: 'white', margin: "6px" }} />
          <Grid container xs={12}>
            <Grid xs={8}>
              <div style={{ display: "flex" }}>
                <div>
                  <img src={location} alt="car"></img>
                </div>
                <div className={classes.vl}></div>
                <div>
                  <p className={classes.details}>Destination: {route && route.toAddress || "-"}  </p>
                </div>
              </div>
            </Grid>
            <Grid xs={4} container justifyContent="flex-end">
              <p className={classes.detailspickuptime}>{route && route.expStartTime && timeFormat(route.expEndTime)} </p>
            </Grid>
          </Grid>
        </Grid>


      </Grid>

    </div>
  );
}
